<template>
  <main-layout>
    <content id="user-page" class="d-block bg-light">
      <header
        class="termuse-header text-center d-flex justify-content-center flex-column"
      >
        <h1>Kullanım Şartları ve Gizlilik</h1>
        <p class="mb-0 mx-auto full">
          Bu Gizlilik Politikası, kişisel verilerinizin tarafımızdan toplanması,
          kullanılması ve işlenmesine dair nitelik, kapsam, amaç ve koşullar ile veri
          sahibi olarak haklarınız hakkında şeffaf bilgiler sağlamayı istediğimiz veri
          koruma beyanımızı içermektedir.
        </p>
        <hr />
        <nav class="bg-transparent" aria-label="breadcrumb">
          <ol
            class="contact-breadcrumb breadcrumb bg-transparent text-white d-inline-flex p-0 m-0"
          >
            <router-link
              tag="li"
              class="contact-link breadcrumb-item cursor-pointer"
              to="/"
            >
              <a class="icon-home"></a>
            </router-link>
            <li class="contact-link breadcrumb-item" aria-current="page">
              Kullanım Şartları ve Gizlilik
            </li>
          </ol>
        </nav>
      </header>
      <main class="termuse-main container full py-5 text-justify">
        <h2 class="text-uppercase">Kullanım Şartları ve Gİzlİlİk</h2>
        <p>
          Sayın Kullanıcı, Doğuş Teknoloji'ye ait
          <a href="https://inovasyon.dogusgrubu.com.tr/"
            >https://inovasyon.dogusgrubu.com.tr/</a
          >
          web sitesine hoş geldiniz.<br />
          <strong
            >"Kullanım Şartları Ve Gizlilik/Kişisel Verilerin Korunması
            Politikası"</strong
          >
          siz değerli kullanıcılarımıza web sitemizde yer alan bilgi ve hizmetlerin
          sağlanmasına ilişkin hükümleri düzenlemektedir.
        </p>
        <p>
          Aşağıdaki tanımlar Doğuş Teknoloji'ye ait
          <a href="https://inovasyon.dogusgrubu.com.tr/"
            >https://inovasyon.dogusgrubu.com.tr/</a
          >
          sitesinin kullanımı için önemlidir.<br />
          Buna göre;
        </p>
        <p>
          <b>Kullanıcı/Kullanıcılar:</b> Kişisel verilerini, sitede yer alan iletişim
          formlarının talep ettiği bilgileri kendi açık rızaları ile vererek siteye üye
          olan veya kişisel verilerini vermeden sadece siteyi ziyaret eden gerçek
          kişileri,
        </p>
        <p>
          <b>Kişisel Veri:</b> Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin
          her türlü bilgiyi,
        </p>
        <p>
          <b>Kişisel Verilerin İşlenmesi:</b> Kişisel verilerin tamamen veya kısmen
          otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla
          otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza
          edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması,
          devralınması, elde edilebilir hâle getirilmesi, sınıflandırılması ya da
          kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen her türlü
          işlemi,
        </p>
        <p>
          <b>Site:</b>
          <a href="https://inovasyon.dogusgrubu.com.tr/"
            >https://inovasyon.dogusgrubu.com.tr/</a
          >
          adresinde yer alan web sitesini,
        </p>
        <p><b>FSEK:</b> 5846 sayılı Fikir ve Sanat Eserleri Kanunu'nu,</p>
        <p>
          <b>5651 sayılı Kanun:</b> İnternet Ortamında Yapılan Yayınların Düzenlenmesi Ve
          Bu Yayınlar Yoluyla İşlenen Suçlarla Mücadele Edilmesi Hakkında Kanun'u,
        </p>
        <p><b>6698 sayılı Kanun:</b> Kişisel Verilerin Korunması Kanunu'nu,</p>
        <p>ifade eder.</p>

        <h2>KULLANIM ŞARTLARI</h2>
        <h3>
          KULLANIM ŞARTLARI, ERİŞİM, RİSK VE SORUMLULUĞUN SINIRLANDIRILMASI, IP ADRESLERİ
          VE COOKİELER (ÇEREZLER)
        </h3>

        <ol>
          <li>
            <p>
              <a href="https://inovasyon.dogusgrubu.com.tr/"
                >https://inovasyon.dogusgrubu.com.tr/</a
              >
              web sitesi ve sitedeki tüm dokümanlar Doğuş Teknoloji'nin mülkiyetindedir.
              Bu dokümanlar, <i>on-line</i> sistemde görülmesi için gerekli olması hali
              haricinde kopyalanamaz, yeniden üretilemez. Yukarıda belirtilenden bağımsız
              olarak, sitenin sayfalarının yazıcı çıktısını kişisel kullanımınız için
              alabilirsiniz. Yukarıda belirtilen genel prensipten bağımsız olarak Doğuş
              Teknoloji, siteden <i>wallpaper</i>, <i>screen saver</i> ve diğer
              programlarını indirme imkanını verebilir. Bu sitede yer alan dokümanlar ile
              ilgili fikri ve sınai mülkiyet haklarını ihlal edecek nitelikteki her türlü
              kullanım sonrasında ortaya çıkabilecek yasal sorumluluklar kullanıcıya
              aittir.
            </p>
          </li>
          <li>
            <p>
              Site ile link oluşturmak isterseniz, bu link
              <a href="https://inovasyon.dogusgrubu.com.tr/"
                >https://inovasyon.dogusgrubu.com.tr/</a
              >
              ana sayfasına yönlendirilmelidir. (<a
                href="https://inovasyon.dogusgrubu.com.tr/"
                >https://inovasyon.dogusgrubu.com.tr/</a
              >)
            </p>
          </li>
          <li>
            <p>
              Doğuş Teknoloji tarafından web sitesinin virüs ve benzeri kötü niyetli
              yazılımlardan arındırılmış olması için mevcut imkanlar dahilinde tedbir
              alınmış olmakla birlikte, nihai güvenliğin sağlanması için kullanıcı, kendi
              virüs koruma sistemini tedarik etmek ve gerekli korumayı sağlamakla
              yükümlüdür. Bu çerçevede kullanıcı,
              <a href="https://inovasyon.dogusgrubu.com.tr/"
                >https://inovasyon.dogusgrubu.com.tr/</a
              >
              web sitesine girmesi nedeniyle, kendi yazılım ve işletim sistemlerinde
              oluşabilecek tüm hata ve bunların doğrudan ya da dolaylı sonuçlarından
              kendisinin sorumlu olduğunu kabul eder.
            </p>
          </li>
          <li>
            <p>
              <a href="https://inovasyon.dogusgrubu.com.tr/"
                >https://inovasyon.dogusgrubu.com.tr/</a
              >
              web sitesinde yer alan her türlü bilgi, resim, açıklama ve haber (kısaca
              "bilgi") sadece tanıtım ve bilgi verme amaçlıdır. Kullanıcı hiçbir şekilde
              web sitesinde yer alan "bilgi"lerin hatalı olduğu ya da bu bilgilere
              istinaden zarara uğradığı iddiasında bulunamaz. Kullanıcı, bilgileri
              referans alarak bir işlem yapmak niyetinde olduğunda, nihai ve güvenilir
              enformasyonu, Doğuş Teknoloji'den temin etmekle yükümlü olduğunu ve web
              sitesinde yayınlanan bilgilerin güncel olmaması nedeniyle Doğuş
              Teknoloji'nin herhangi bir sorumluluğu olmadığını kabul eder.
            </p>
          </li>
          <li>
            <p>
              Doğuş Teknoloji, kendi takdirinde olmak üzere sitenin içeriğini dilediği
              zaman değiştirme, kullanıcılara sağlanan herhangi bir hizmeti değiştirme ya
              da sona erdirme ve
              <a href="https://inovasyon.dogusgrubu.com.tr/"
                >https://inovasyon.dogusgrubu.com.tr/</a
              >
              web sitesinde kayıtlı kullanıcı bilgi ve verilerini silme hakkını saklı
              tutar. Doğuş Teknoloji, web sitesinin hatasız olması için her türlü tedbir
              almış olmakla birlikte, sitede mevcut ya da oluşabilecek hatalar ile ilgili
              herhangi bir garanti vermemektedir.
            </p>
          </li>
          <li>
            <p>
              Resmi makamlardan kullanıcıya yönelik bir suç duyurusu ya da resmi
              soruşturma talebi gelmesi ve/veya kullanıcının Doğuş Teknoloji sistemlerinin
              çalışmasına engel olacak ya da işleyişini değiştirecek herhangi bir
              elektronik sabotaj veya saldırı yaptığının tespit edilmesi halinde, Doğuş
              Teknoloji, kullanıcının kimlik bilgilerini araştırma ve yasal mercilere
              bildirimde bulunma hakkına sahiptir.
            </p>
            <p>
              Doğuş Teknoloji web sitesi ile sahip olduğu tüm alt sitelerinin ve diğer
              bilgi sistemlerinin güvenliğini sağlamak Doğuş Teknoloji sorumluluğundadır.
              Ticari amaçlı, iyi niyetli ya da başka bir amaçla, web siteleri ve diğer
              bilgi teknolojileri sistemlerinde bulunabilecek zafiyetlerin tespitine
              yönelik taramalar ve tespit edilebilecek zafiyetlerin suiistimal edilerek
              sistemlere zarar verilmesi, bilgi sızdırma vb. faaliyetler yasa dışıdır. Bu
              gibi faaliyetlerin gerçekleştirilmesi durumunda 5651 sayılı İnternet
              Ortamında Yapılan Yayınların Düzenlenmesi ve Bu Yayınlar Yoluyla İşlenen
              Suçlarla Mücadele Edilmesi Hakkında Kanun uyarınca yaptırım uygulanabilir.
              Bu gibi girişimlerde bulunulması halinde, Doğuş Teknoloji yapılan saldırının
              tipi, saldırı zamanı, saldırgana ilişkin yasa kapsamında toplanan verilerle
              birlikte hukuki süreç başlatma ve süreç sonucunda para cezası ya da hapis
              cezasına varacak cezaları talep etme hakkını saklı tutar.
            </p>
          </li>
          <li>
            <p>
              Bazı durumlarda, şahsınıza özel olmayan bilgiler toplanabilir. Bu tip
              bilgilere örnek olarak kullandığınız internet tarayıcısının türü, işletim
              sisteminiz, sitemize linkle ya da ilanla ulaştığınız sitenin domain ismi
              verilebilir.
            </p>
          </li>
          <li>
            <h4>
              5846 Sayılı Fikir Ve Sanat Eserleri Kanunu ve 5651 Sayılı İnternet Ortamında
              Yapılan Yayınların Düzenlenmesi Ve Bu Yayınlar Yoluyla İşlenen Suçlarla
              Mücadele Edilmesi Hakkında Kanun Uyarınca Dikkat Edilecek Hususlar
            </h4>
            <p>
              Kullanıcı/Kullanıcılar, web sitesi üzerinde bir başka web sitesine veya
              içeriğe erişim imkanı tanıyan link'ler bulunabileceğini, bunların
              kontrolünün veya doğruluğunun veya yeterliliğinin Doğuş Teknoloji ile ilgisi
              olmadığını ve Doğuş Teknoloji'nin bu siteleri herhangi bir şekilde
              desteklemediğini veya içerdiği bilgilerin doğruluğunu garanti etmediğini ve
              bu sitelerde yer alan içeriklerden dolayı herhangi bir sorumluluğu
              bulunmadığını, bu sitelerin ve/veya içeriklerin kullanımından veya bu
              içeriklere dayalı olarak almış oldukları kararların sonuçlarından, sitenin
              kullanım şartlarını okumamış veya okumuş ancak bunlara uygun davranmamış
              olmaları sebebiyle uğramış olduğu maddi veya manevi, müspet veya menfi ve
              olası herhangi bir zarardan veya masraftan Doğuş Teknoloji veya herhangi bir
              grup şirketinin veya çalışanlarının, yöneticilerinin, yetkilendirdikleri
              kişi veya kuruluşlarının, hukuki veya cezai hiçbir sorumluluğu olmadığını,
              sitede yer alan bilgilerin doğruluğu ve/veya yeterliliğinin Doğuş Teknoloji
              tarafından garanti edilmediğini kabul ve beyan etmişlerdir.
            </p>
            <p>
              Kullanıcı/Kullanıcılar, web sitesi üzerinde Doğuş Teknoloji'ye veya üçüncü
              bir kişi veya kuruluşa ait herhangi bir yazı, bilgi, yorum, görüş, haber,
              görüntü, resim, şekil, grafik ve sair bir materyal bulunabileceğini, Doğuş
              Teknoloji'nin bu materyallerle ilgili olarak Fikir ve Sanat Eserleri
              Kanunu'nun düzenlediği yasal haklara sahip olduğunu, bunların Doğuş
              Teknoloji izni olmadan kopyalanamayacağını, değiştirilemeyeceğini,
              çoğaltılamayacağını, yayınlanamayacağını, satılamayacağını veya
              kullandırılamayacağını veya bunlara müdahale edilemeyeceğini kabul ve beyan
              etmişlerdir.
            </p>
            <p>
              Bu sitede yayınlanan tüm logolar, marka ve kurumsal amblemler 556 sayılı
              Markaların Korunması Hakkında Kanun Hükmünde Kararname hükümleri ile
              korunmaktadırlar. Logolar hiçbir şekilde kopyalanamaz ve kullanılamazlar.
              Kullanıcı/Kullanıcılar, Doğuş Teknoloji logosunun veya web sitesine ait
              sayfaların bir başka web sitesinde herhangi bir şekilde yer almasından veya
              web sitesine üçüncü kişiler veya ziyaretçiler tarafından yapılabilecek
              herhangi bir içerik yüklemesinden dolayı elde edilecek bilgilerin
              kullanımından veya internetin genel uygulamalar çerçevesinde kullanımı
              dolayısıyla sorumluluk doğurmayan herhangi bir içerikten Doğuş Teknoloji'nin
              veya bağlı olduğu herhangi bir grup şirketinin veya çalışanlarının,
              yöneticilerinin, yetkilendirdikleri kişi veya kuruluşlarının, hukuki veya
              cezai hiçbir sorumluluğu olmadığını kabul ve beyan etmişlerdir.
            </p>
          </li>
          <li>
            <p>
              Doğuş Teknoloji,
              <a href="https://inovasyon.dogusgrubu.com.tr/"
                >https://inovasyon.dogusgrubu.com.tr/</a
              >
              web sitesinde, "Kullanım Şartları"nın herhangi bir maddesini, bildirimde
              bulunmaksızın değiştirme, yenileme veya iptal etme hakkına sahiptir.
              Değiştirilen, yenilenen ya da yürürlükten kaldırılan her hüküm, yayın
              tarihinde tüm kullanıcılar bakımından hüküm ifade edecektir.
            </p>
          </li>
        </ol>
      </main>
    </content>
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/MainLayout.vue";
export default {
  name: "TermUse",
  components: {
    MainLayout,
  },
};
</script>

<style></style>
